@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto&family=Roboto+Condensed:wght@100;300;400;500;700;900&display=swap");
@import "~bulma/sass/utilities/_all";
@import "bulma/sass/components/modal";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

:root {
  font-size: 15px;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

.font-audiowide {
  font-family: "Audiowide", cursive;
}

.font-roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
}

.text-gradient {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gold {
  color: #e2d27f;
}

.text-brownish-grey {
  color: #6f6f6f;
}

.silver {
  background-image: linear-gradient(
    60deg,
    #727377 13%,
    #adaeb2 36%,
    #c7c8ca 51%,
    #e0e1e3 65%,
    #f9f9f9 77%,
    #ffff 88%
  );
}

.gold {
  background-image: linear-gradient(to bottom, #faf6a6 0%, #c5a44e 100%);
}

.blue {
  background-image: linear-gradient(to bottom, #1921ae 0%, #1a60f9 100%);
}

.card-border-gradient-gray-to-white {
  background: linear-gradient(#060606, #060606) padding-box,
    linear-gradient(140deg, #151515, #5b5d59) border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-blue-to-yellow {
  background: linear-gradient(#060606, #060606) padding-box,
    linear-gradient(
        40deg,
        rgba(27, 96, 250, 1) 0%,
        rgba(2, 13, 30, 1) 50%,
        rgba(251, 235, 98, 1) 100%
      )
      border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-blue-to-yellow-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(
        40deg,
        rgba(27, 96, 250, 1) 0%,
        rgba(2, 13, 30, 1) 50%,
        rgba(251, 235, 98, 1) 100%
      )
      border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-blue-to-purple-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(71deg, #1921ae 10%, #1a60f9 80%) border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-light-gradient-silver-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(326deg, #67717a 0%, #0f100f 100%) border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-blue-bg-gradient-blue {
  background: linear-gradient(55deg, #1921ae 11%, #1a60f9 82%) padding-box,
    linear-gradient(326deg, #7293e9 0%, #2862ee 100%) border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-blue-to-yellow-bg-dark-blue-gray {
  background: linear-gradient(180deg, #0e1a3a 34%, #0d0f22 56%) padding-box,
    linear-gradient(
        40deg,
        rgba(27, 96, 250, 1) 0%,
        rgba(2, 13, 30, 1) 50%,
        rgba(251, 235, 98, 1) 100%
      )
      border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-gradient-gold-bg-dark-blue-gray {
  background: linear-gradient(180deg, #0e1a3a 34%, #0d0f22 56%) padding-box,
    linear-gradient(
      to right,
      #fccf61 0%,
      #905613 28%,
      #965d17 37%,
      #a87325 51%,
      #c4973b 68%,
      #ecc859 88%,
      #fad964 94%
    )
    border-box;
  border-radius: 1rem;
  border: 1px solid transparent;
}

.card-border-brown-bg-blue {
  background-color: #04132c;
  border-radius: 10px;
  border: 1px solid #353734;
}

.card-popular-number {
  background-color: #04132c;
  border-radius: 10px;
  border: 1px solid #353734;
}

.card-popular-number:hover {
  background: linear-gradient(170deg, #c5a44e 8%, #faf6a6 100%), border-box;
  border-radius: 10px;
  border: 1px solid transparent;
}

.card-popular-number:hover .card-add {
  opacity: 1;
}

.card-popular-number:hover .card-opacity {
  opacity: 0.4;
}

.card-border-gold-bg-blue {
  background-color: #04132c;
  border-radius: 15px;
  border: 1px solid #e2d27f;
}

.card-border-gradient-gold-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(
        to right,
        #fccf61 0%,
        #905613 28%,
        #965d17 37%,
        #a87325 51%,
        #c4973b 68%,
        #ecc859 88%,
        #fad964 94%
      )
      border-box;
  border-radius: 1rem;
  border: 3px solid transparent;
}

@media screen and (max-width: 500px) {
  .modal-content,
  .modal-card {
    margin: 0px;
  }
}

@media screen and (max-width: 1150px) {
  .card-border-gradient-gold-bg-black-to-blue {
    background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
      linear-gradient(
          to right,
          #fccf61 0%,
          #905613 28%,
          #965d17 37%,
          #a87325 51%,
          #c4973b 68%,
          #ecc859 88%,
          #fad964 94%
        )
        border-box;
    border-radius: 1rem;
    border: 1px solid transparent;
  }
}

.card-orange {
  background-color: #ea8b18;
  border-radius: 1rem;
}

.card-sky {
  background-color: #8198ee;
  border-radius: 1rem;
}

.card-violet {
  background-color: #8247e5;
  border-radius: 1rem;
}

.card-dark-blue {
  background-image: linear-gradient(to bottom, #0e1a3a 0%, #0d0f22 100%);
  border-radius: 1rem;
}

.card-gold {
  background-color: #965d17;
  background-image: linear-gradient(90deg, #965d17 0%, #fccf61 100%);
  border-radius: 1rem;
}

.card-silver {
  background-image: linear-gradient(
    to right,
    #e9e9e9 -35%,
    #e9e9e9 -25%,
    #e9e9e9 -14%,
    #bdbdbd -9%,
    #9a9a9a 2%,
    #6a6a6a 10%,
    #ffffff 34%,
    #b3b3b2 46%,
    #e8e8e8 61%,
    #868686 69%,
    #dadad9 151%
  );
  border-radius: 1rem;
}

.card-border-gradient-blue-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%);
  border-radius: 1rem;
  border: 2px solid #0e1a39;
}

.card-border-gradient-gold-bg-black {
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(
        to right,
        #fccf61 0%,
        #905613 28%,
        #965d17 37%,
        #a87325 51%,
        #c4973b 68%,
        #ecc859 88%,
        #fad964 94%
      )
      border-box;
  border-radius: 12px;
  border: 2px solid transparent;
}

.card-border-gradient-gold-bg-black.border-sm {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(
        to right,
        #fccf61 0%,
        #905613 28%,
        #965d17 37%,
        #a87325 51%,
        #c4973b 68%,
        #ecc859 88%,
        #fad964 94%
      )
      border-box;
  border: 1px solid transparent;
}

.card-border-blue-bg-black {
  background: #000000;
  border-radius: 12px;
  border: 2px solid #0e1a39;
}

.card-gradient-green {
  background-image: linear-gradient(to bottom, rgba(70, 185, 0, 0) 0%, #21551b 90%);
  border-radius: 1rem;
  border: 2px solid #46b900;
}

.card-gradient-red {
  background-image: linear-gradient(to bottom, rgba(246, 80, 80, 0) 0%, #5f100e 90%);
  border-radius: 1rem;
  border: 2px solid #ff1010;
}

.card-border-gradient-silver-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(to right, #ffffff 0%, #8e8f93 28%, #8e8f93 68%, #d9dadb 83%) border-box;
  border-radius: 1rem;
  border: 3px solid transparent;
}

.bg-gradient-black {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(10, 23, 43, 1) 30%,
    rgba(10, 23, 43, 1) 70%,
    rgba(0, 0, 0, 1) 100%
  );
}

.bg-our-game {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(10, 23, 43, 0) 30%,
      rgba(10, 23, 43, 0) 70%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../public/img/bg/our-game.webp") center center;
  background-size: cover;
}

.bg-gradient-blue {
  background: linear-gradient(84deg, #1921ae 11%, #1a60f9 82%);
}

.bg-blue-gray {
  background-color: #04132c;
}

.bg-dark-blue-gray {
  background-color: #0e1a3a;
}

.bg-gradient-black-to-blue {
  background-color: #000000;
  background-image: linear-gradient(90deg, #000 35%, #0f2a56 100%);
}

.bg-gradient-black-to-br {
  background-color: #000000;
  background-image: linear-gradient(140deg, #000000 0%, #09172b 100%);
}

.bg-gradient-black-to-blue-r {
  background-color: #000000;
  background: linear-gradient(119deg, #000 36%, #0a172b 56%);
}

.bg-gradient-blue {
  background-image: linear-gradient(82deg, #1921ae 11%, #1a60f9 82%);
}

.bg-gradient-gold-to-blue {
  background-color: #000000;
  background-image: linear-gradient(
    40deg,
    #1b60fa 22%,
    #09307b 45%,
    #020d1e 68%,
    #d4b351 95%,
    #fbeb62 110%
  );
}

.bg-gradient-yellow-to-blue {
  background-color: #000000;
  background-image: linear-gradient(
    40deg,
    rgba(27, 96, 250, 1) 0%,
    rgba(2, 13, 30, 1) 50%,
    rgba(251, 235, 98, 1) 100%
  );
}

.btn-border-gradient-gold {
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(278deg, #fadda6 99%, #ffca48 40%, #c5a44e 16%) border-box;
  border: 1px solid transparent;
}
.btn-border-gradient-gold-bg-blue {
  background: linear-gradient(#0a172b, #0a172b) padding-box,
    linear-gradient(278deg, #fadda6 99%, #ffca48 40%, #c5a44e 16%) border-box;
  border: 1px solid transparent;
}

.btn-border-gradient-silver-bg-blue {
  background: linear-gradient(#0a172b, #0a172b) padding-box,
    linear-gradient(to right, #ffffff 0%, #8e8f93 28%, #8e8f93 68%, #d9dadb 83%) border-box;
  border: 1px solid transparent;
}

.btn-border-gradient-blue {
  background: linear-gradient(#0a172b, #0a172b) padding-box,
    linear-gradient(78deg, #1921ae 10%, #1a60f9 81%) border-box;
  border: 1px solid transparent;
}

.btn-border-gradient {
  background: linear-gradient(#000000, #000000) padding-box,
    linear-gradient(
        40deg,
        rgba(27, 96, 250, 1) 0%,
        rgba(2, 13, 30, 1) 50%,
        rgba(251, 235, 98, 1) 100%
      )
      border-box;
  border: 2px solid transparent;
}

.bg-gold {
  background-color: #faf6a6;
  background-image: linear-gradient(296deg, #faf6a6 0%, #c5a44e 100%);

  &:disabled {
    cursor: not-allowed;
    background-image: none;
  }
}

.ellipse-border-gradient-blue-to-yellow-bg-black-to-blue {
  background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(84deg, #1921ae 11%, #1a60f9 82%) border-box;
  border-radius: 50%;
  border: 3px solid transparent;
}

.ellipse-border-gold-bg-blue {
  background-color: #04132c;
  border-radius: 50%;
  border: 2px solid #e2d27f;
}

.ellipse-border-gradient-gold{
  background: linear-gradient(to bottom, #0e1a3a 34%, #0d0f22 56%) padding-box,
    linear-gradient(54deg, #fccf61 10%, #905613 33%, #965d17 40%, #a87325 52%, #c4973b 66%, #ecc859 82%, #fad964 87%) border-box;
  border-radius: 50%;
  border: 3px solid transparent;
}

.tb-winning-ranks {
  border-collapse: separate;
  border-spacing: 0 10px;
  td:first-child {
    border-radius: 10px 0 0 10px;
  }
  td:last-child {
    border-radius: 0 10px 10px 0;
  }
}

.scrollbar-gray::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.scrollbar-gray::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.scrollbar-gray::-webkit-scrollbar-thumb {
  background: #ffffff25;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.scrollbar-gray::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.scrollbar-gray::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.scrollbar-gray::-webkit-scrollbar-track {
  background: #ffffff08;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.scrollbar-gray::-webkit-scrollbar-track:hover {
  background: #ffffff08;
}
.scrollbar-gray::-webkit-scrollbar-track:active {
  background: #333333;
}
.scrollbar-gray::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar-gold-sm::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}
.scrollbar-gold-sm::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.scrollbar-gold-sm::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.2, #fadda6),
    color-stop(0.55, #ffca48),
    color-stop(0.86, #c5a44e)
  );
  border: 0px none #ffffff;
  border-radius: 50px;
}
.scrollbar-gold-sm::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.scrollbar-gold-sm::-webkit-scrollbar-thumb:active {
  background: #000000;
}
.scrollbar-gold-sm::-webkit-scrollbar-track {
  background: #ffffff15;
  border: 0px none #ffffff;
  border-radius: 50px;
}
.scrollbar-gold-sm::-webkit-scrollbar-track:hover {
  background: #ffffff15;
}
.scrollbar-gold-sm::-webkit-scrollbar-track:active {
  background: #333333;
}
.scrollbar-gold-sm::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar-gold::-webkit-scrollbar-corner {
  background: transparent;
}

.scrollbar-gold::-webkit-scrollbar-track {
  border: 0px none #ffffff;
  border-radius: 10px;
  background-color: #0e142b;
  width: 6px;
}

.scrollbar-gold::-webkit-scrollbar {
  width: 6px;
}

.scrollbar-gold::-webkit-scrollbar-thumb {
  border: 0px none #ffffff;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.44, #fadda6),
    color-stop(0.72, #ffca48),
    color-stop(0.86, #c5a44e)
  );
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

@layer utilities {
  .glow-gold {
    -webkit-box-shadow: 0px 2px 13px 0px rgba(250, 218, 100, 0.8);
    -moz-box-shadow: 0px 2px 13px 0px rgba(250, 218, 100, 0.8);
    box-shadow: 0px 2px 13px 0px rgba(250, 218, 100, 0.8);
  }

  .bg-gradient-gold-tb {
    background-color: #faf6a6;
    background-image: linear-gradient(#fccf62 0%, #996119 100%);
  }

  .bg-gold {
    background-color: #faf6a6;
    background-image: linear-gradient(296deg, #faf6a6 0%, #c5a44e 100%);
  }

  .border-gold {
    background: linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
      linear-gradient(
          47deg,
          #fccf61 10%,
          #905613 32%,
          #965d17 40%,
          #a87325 51%,
          #c4973b 64%,
          #ecc859 80%,
          #fad964 85%
        )
        border-box;
    border-radius: 1rem;
    border: 1px solid transparent;
  }

  .bg-black-border-gold {
    background: linear-gradient(#000000, #000000) padding-box,
      linear-gradient(
          47deg,
          #fccf61 10%,
          #905613 32%,
          #965d17 40%,
          #a87325 51%,
          #c4973b 64%,
          #ecc859 80%,
          #fad964 85%
        )
        border-box;
    border-radius: 1rem;
    border: 1px solid transparent;
  }

  .bg-gradient-gold-max {
    background: rgb(252, 207, 97);
    background: linear-gradient(
      60deg,
      #fccf61 8%,
      #905613 33%,
      #965d17 41%,
      #a87325 54%,
      #c4973b 70%,
      #ecc859 88%,
      #fad964 93%
    );
  }
  .btn-black-border-gold {
    background: linear-gradient(#060606, #060606) padding-box,
      linear-gradient(
          47deg,
          #fccf61 10%,
          #905613 32%,
          #965d17 40%,
          #a87325 51%,
          #c4973b 64%,
          #ecc859 80%,
          #fad964 85%
        )
        border-box;
    border: 1px solid transparent;
  }

  .bg-blur-blue {
    border-radius: 40px;
    -webkit-filter: blur(30px);
    filter: blur(30px);
    background-image: linear-gradient(80deg, #1921ae 11%, #1a60f9 82%);
  }

  .bg-blue-border-gold {
    background: linear-gradient(180deg, #0e1a3a 0%, #0d0f22 100%) padding-box,
      linear-gradient(
          47deg,
          #fccf61 10%,
          #905613 32%,
          #965d17 40%,
          #a87325 51%,
          #c4973b 64%,
          #ecc859 80%,
          #fad964 85%
        )
        border-box;
    border-radius: 1rem;
    border: 1px solid transparent;
  }

  .clip-skew {
    clip-path: polygon(0% 0, 100% 0%, 0% 130%);
  }

  .bg-gradient-blue {
    background: #1a60f9;
    background: linear-gradient(84deg, #1921ae 11%, #1a60f9 82%);
  }

  .bg-gradient-gold {
    background: linear-gradient(170deg, #c5a44e 8%, #faf6a6 100%), border-box;
  }

  .gold-max {
    background-image: linear-gradient(
      0deg,
      #ecc859 0%,
      #905613 19%,
      #c4973b 36%,
      #f0da68 59%,
      #daa453 81%
    );
  }

  .transition-filter {
    -webkit-transition: 1s -webkit-filter linear;
    -moz-transition: 1s -moz-filter linear;
    -moz-transition: 1s filter linear;
    -ms-transition: 1s -ms-filter linear;
    -o-transition: 1s -o-filter linear;
    transition: 1s filter linear, 1s -webkit-filter linear;
  }

  .bubble-orange::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 95%;
    left: 50%;
    margin-left: -0.5rem;
    border: 0.5rem solid transparent;
    border-bottom: none;

    border-top-color: #f7931a;
  }

  /* Float */
  .hvr-float {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-float:hover,
  .hvr-float:focus,
  .hvr-float:active {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }

  /* Buzz */
  @-webkit-keyframes hvr-buzz {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  @keyframes hvr-buzz {
    50% {
      -webkit-transform: translateX(3px) rotate(2deg);
      transform: translateX(3px) rotate(2deg);
    }
    100% {
      -webkit-transform: translateX(-3px) rotate(-2deg);
      transform: translateX(-3px) rotate(-2deg);
    }
  }
  .hvr-buzz {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  }
  .hvr-buzz:hover,
  .hvr-buzz:focus,
  .hvr-buzz:active {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
}

.apexcharts-tooltip {
  border: 0 !important;
  color: #04132c !important;
  background: #e2d27f !important;
  font-size: 12px !important;
  border-radius: 20px !important;
}

[role="tooltip"].popup-content {
  width: 220px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

input[type="range"] {
  -webkit-appearance: none;
  height: 12px;
  background: #080d1c;
  border-radius: 15px;
  background-image: linear-gradient(360deg, #1921ae 11%, #1a60f9 72%);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 33px;
  width: 30px;
  background: transparent;
  background-image: url("../src/assets/icon/bb-icon-s.webp");
  background-clip: border-box;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="range"].slider-sm {
  height: 15px;
}

input[type="range"].slider-sm::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 38px;
  width: 34px;
  background-image: url("../src/assets/icon/bb-icon-s.webp");
  background-clip: border-box;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;
}

input[type="range"].slider-sm::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

#percent {
  position: absolute;
}

.swiper-slide,
swiper-slide {
  width: auto !important;
}

.apexcharts-svg.apexcharts-zoomable.hovering-pan {
  cursor: crosshair !important;
}

.popup-content {
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
